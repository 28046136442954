<template>
  <div id="worksheetTypeDefinitionForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="worksheetTypeDefinition.worksheetType"
        label="Type"
        placeholder="Create a name for this worksheet type"
        :required="true"
        :rules="[
          () =>
            !!worksheetTypeDefinition.worksheetType || 'This field is required',
        ]"
      />

      <div class="d-flex flex-column flex-md-row">
        <div>
          <v-subheader> Worksheet Options </v-subheader>
          <div class="mx-10">
            <v-radio-group
              v-model="worksheetTypeDefinition.hasWeightSettings"
              row
            >
              <template v-slot:label>
                <div>Has Weight Setings</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group
              v-if="worksheetTypeDefinition.hasWeightSettings"
              v-model="worksheetTypeDefinition.hasDeductibleWeight"
              row
            >
              <template v-slot:label>
                <div>Has Deductible Weight</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group
              v-if="worksheetTypeDefinition.hasWeightSettings"
              v-model="worksheetTypeDefinition.hasTargetWeight"
              row
            >
              <template v-slot:label>
                <div>Has Target Weight</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasSupplier" row>
              <template v-slot:label>
                <div>Has Supplier</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasCustomer" row>
              <template v-slot:label>
                <div>Has Customer</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group
              v-model="worksheetTypeDefinition.hasInventorySelection"
              row
            >
              <template v-slot:label>
                <div>Has Inventory Selection</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group
              v-model="worksheetTypeDefinition.hasNetAdjustmentWeight"
              row
            >
              <template v-slot:label>
                <div>Has Net Adjustment Weight</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasDocumentNumber" row>
              <template v-slot:label>
                <div>Has Document Number</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasNotes" row>
              <template v-slot:label>
                <div>Has Notes</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasLocation" row>
              <template v-slot:label>
                <div>Has Location</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasMemo" row>
              <template v-slot:label>
                <div>Has Memo</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasProcessType" row>
              <template v-slot:label>
                <div>Has Process Type</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasProduct" row>
              <template v-slot:label>
                <div>Has Product</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
        <div>
          <v-subheader> Entry Options </v-subheader>

          <div class="mx-10">
            <v-radio-group v-model="worksheetTypeDefinition.hasEntryPhoto" row>
              <template v-slot:label>
                <div>Has Entry Photo</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="worksheetTypeDefinition.hasEntryNotes" row>
              <template v-slot:label>
                <div>Has Entry Notes</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>

          <div>
          <v-subheader> Signature Options </v-subheader>

          <div class="mx-10">
            <v-radio-group v-model="worksheetTypeDefinition.hasSignature" row>
              <template v-slot:label>
                <div>Has Signature(s)</div>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Yes</div>
                </template>
              </v-radio>

              <v-radio :value="false">
                <template v-slot:label>
                  <div>No</div>
                </template>
              </v-radio>
            </v-radio-group>

          </div>
        </div>
        </div>
      </div>
      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getWorksheetTypeDefinition } from "@/services/worksheetTypeDefinition";

import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      worksheetTypeDefinition: {
        relations: {},
        worksheetType: "",
        hasWeightSettings: true,
        hasLocation: true,
        hasProcessType: true,
        hasProduct: true,
        hasDeductibleWeight: false,
        hasTargetWeight: false,
        hasNetAdjustmentWeight: false,
        hasInventorySelection: false,
        hasDocumentNumber: false,
        hasMemo: false,
        hasEntryPhoto: false,
        hasNotes: false,
        hasEntryNotes: true,
        hasSupplier: false,
        hasCustomer: false,
        hasSignature: false
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("worksheetTypeDefinition", [
      "createWorksheetTypeDefinition",
      "updateWorksheetTypeDefinition",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.addMessage("Please check your input");
        return;
      }

      if (this.id) {
        let worksheetTypeDefinition = this.worksheetTypeDefinition;
        delete worksheetTypeDefinition.relations;

        this.updateWorksheetTypeDefinition(worksheetTypeDefinition)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("Worksheet Type was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating Worksheet Type, please try again later"
            );
          });
      } else {
        let worksheetTypeDefinition = this.worksheetTypeDefinition;
        delete worksheetTypeDefinition.relations;

        this.createWorksheetTypeDefinition(worksheetTypeDefinition)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("Worksheet Type was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating Worksheet Type, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.worksheetTypeDefinition.id = this.id;
        getWorksheetTypeDefinition(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(
                this.worksheetTypeDefinition,
                property
              )
            ) {
              this.worksheetTypeDefinition[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {},
  },
  components: {},
};
</script>
